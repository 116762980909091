import { keyframes } from 'styled-components';

const beat = keyframes`
  0% {
    transform: scale(.75);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(.75);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(.75);
  }
  100% {
    transform: scale(.75);
  }
`;

export default beat;
